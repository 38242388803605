#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
}